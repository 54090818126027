import React from "react"
import { Link } from "gatsby"
import { Icon } from "react-icons-kit"
import { ic_web_outline } from "react-icons-kit/md/ic_web_outline"
import { code } from "react-icons-kit/entypo/code"
import { ic_saved_search } from "react-icons-kit/md/ic_saved_search"
import { stopwatch } from "react-icons-kit/entypo/stopwatch"
import { shield } from "react-icons-kit/fa/shield"
import { signal } from "react-icons-kit/fa/signal"
import { cloud } from "react-icons-kit/fa/cloud"
import { globe } from "react-icons-kit/fa/globe"
import { gears } from "react-icons-kit/fa/gears"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import PageHeader from "../components/PageHeader"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

import IconCo2 from "../svg/CO2.svg"

const IndexES = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": "https://netbistrot.com/pt/",
        url: "https://netbistrot.com/pt/",
        name: "NetBistrot",
        inLanguage: "pt-BR",
      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency"
        description="Grandes websites para pequenos negócios."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="web design, web studio, serverless, user experience, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="wrapper z-0">
        <PageHeader />
        <div className="main mx-2">
          <div className="section section-typo">
            <div className="mx-auto max-w-4xl">
              <div className="text-center ">
                <span className="text-4xl">
                  Grandes websites para pequenos negócios.
                </span>
              </div>
              <div className="h-8" />
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="col-span-2" />
                <div className=" col-span-8">
                  <p className="text-2xl">O que fazemos:</p>
                  <div className="h-4" />
                  <p className="text-2xl pb-4">Websites de alto desempenho</p>
                  <p className="text-2xl">Páginas super rápidas</p>
                  <p className="text-xl pl-6">melhor experiência de usuário</p>
                  <p className="text-xl pl-6">melhor envolvimento do usuário</p>
                  <p className="text-xl pl-6">melhor classificação SEO</p>
                  <div className="h-4" />
                  <p className="text-2xl">Menor consumo elétrico</p>
                  <p className="text-xl pl-6">responsabilidade ambiental</p>
                  <div className="h-4" />
                  <p className="text-2xl">Menores custos de manutenção</p>
                  <div className="h-4" />
                  <p className="text-2xl">Otimização de mecanismos de busca</p>
                  <p className="text-xl pl-6">melhor classificação SEO</p>
                  <div className="h-4" />
                  <p className="text-2xl">Escalabilidade</p>
                  <p className="text-xl pl-6">
                    acesso garantido mesmo em picos de alta demanda
                  </p>
                  <div className="h-8" />
                  <Link
                    className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                    to="/pt/static-websites/"
                  >
                    Como fazemos isso?
                  </Link>
                </div>
              </div>
              <div className="h-16" />
              <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                <div className="text-center ">
                  <span className="text-2xl">
                    Estes são os conceitos por trás de nosso trabalho:
                  </span>
                </div>
              </div>
              <div className="h-10" />
              <div className="grid grid-cols-2 gap-x-10 gap-y-16">
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={ic_web_outline} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-12">
                      <span className="text-2xl">design personalizado</span>
                      <br />
                      <span className="text-lg">
                        Suas necessidades não são as mesmas que as de outros e
                        um design personalizado ajuda a melhorar sua presença
                        online. Nós criamos um web design personalizado para seu
                        projeto para suprir qualquer requerimento técnico ou
                        estético.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={code} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">código personalizado</span>
                      <br />
                      <span className="text-lg">
                        Suas necessidades não são as mesmas que as de outros e
                        um código personalizado solucionará qualquer necessidade
                        que você possa ter.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={cloud} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">cloud hosting</span>
                      <br />
                      <span className="text-lg">
                        Técnicas cloud estão evoluindo constantemente e nós
                        utilizamos as mais recentes para alojar seus sistemas.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={ic_saved_search} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">melhores práticas SEO</span>
                      <br />
                      <span className="text-lg">
                        Nós preenchemos todos os requerimentos dos principais
                        motores de busca e das redes sociais para que os
                        algoritmos deles localizem facilmente seu sistema e dê a
                        eles a melhor classificação.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <IconCo2
                        style={{
                          width: "100%",
                          height: "auto",
                          maxWidth: "64px",
                          display: "inline",
                        }}
                      />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">
                        mínima pegada de CO<sub>2</sub>
                      </span>
                      <br />
                      <span className="text-lg">
                        Nós desenhamos sistemas que utilizam os recursos da
                        nuvem apenas quando são necessários. Isso significa que
                        o consumo elétrico é mínimo.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon
                        size={64}
                        style={{ maxWidth: "86px" }}
                        icon={shield}
                      />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">
                        segurança em primeiro lugar
                      </span>
                      <br />
                      <span className="text-lg">
                        A não ser que seja estritamente necessário não
                        solicitamos um login. E quando for, nós utilizamos as
                        técnicas mais recentes. Cada sistema de base só pode ser
                        acessado por autenticação com 2 fatores com as
                        permissões mínimas para a tarefa em questão.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={stopwatch} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">desempenho rápido</span>
                      <br />
                      <span className="text-lg">
                        Nós utilizamos vários recursos para garantir que seu
                        sistema seja tão rápido quanto possível e seus usuário
                        perceberão isso. Como os motores de busca também
                        valorizam a velocidade este requisito é de máxima
                        importância.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={signal} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">escalabilidade</span>
                      <br />
                      <span className="text-lg">
                        Como os sistemas que desenvolvemos são serverless, e
                        utilizam recursos da nuvem apenas quando são
                        necessários, temos muito mais flexibilidade que um
                        sistema baseado em servidores para reagir quando há um
                        pico de requisições ou para desacelerar quando há poucos
                        usuários.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={cloud} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">natural da nuvem</span>
                      <br />
                      <span className="text-lg">
                        Nós desenhamos sistemas que utilizam as características
                        mais recentes da nuvem. Nós substituímos servidores por
                        microserviços que rodam apenas quando são necessários e
                        CDNs (content distribution networks) que estão sempre
                        disponíveis.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={globe} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">CDN</span>
                      <br />
                      <span className="text-lg">
                        Os sistemas que desenvolvemos são implantados em uma
                        dezena de localizações ao redor do globo para asegurar
                        que seus usuários receberão os conteúdos de um CDN
                        próximo com um atraso mínimo onde quer que estejam.
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" col-span-2 md:col-span-1 grid grid-cols-1 gap-4">
                  <div>
                    <div className="w-3/12">
                      <Icon size={64} icon={gears} />
                    </div>
                    <div className="ml-2 col-span-1 md:col-span-10">
                      <span className="text-2xl">API</span>
                      <br />
                      <span className="text-lg">
                        Nós desenvolvemos APIs para solucionar as necessidades
                        de seus sistemas e para expadir suas habilidades através
                        de recursos de terceiros, como sistemas de pagamento ou
                        de empresas de logística.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-20" />
        </div>
        <Footer lang="pt" />
      </div>
    </>
  )
}

export default IndexES
